<template>
  <div>
    <div
      v-show="loaded || cover?.$el.complete"
      :class="[
        'relative w-full overflow-hidden rounded-xl bg-cover bg-center',
        imageRatio
      ]"
      :style="
        'background-image: url(' +
        $img(
          image.src || useImagePlaceholder('banner'),
          {},
          {
            provider: image.src && 'directus'
          }
        ) +
        ')'
      ">
      <div class="h-full w-full bg-neutral-900/25 backdrop-blur-sm">
        <NuxtImg
          sizes="sm:50vw md:200px lg:400px xl:800px"
          ref="cover"
          :src="image.src || useImagePlaceholder('banner')"
          :alt="image.alt"
          :provider="image.src ? image.provider : null"
          fit="contain"
          class="h-full w-full object-contain"
          @load="loaded = true" />
        <div
          class="absolute bottom-0 left-0 flex w-full flex-wrap justify-start gap-x-1 gap-y-1 px-3.5 py-2">
          <slot />
        </div>
      </div>
    </div>
    <div
      v-if="!(loaded || cover?.$el.complete)"
      role="status"
      class="aspect-video w-full animate-pulse rounded-lg bg-neutral-600"></div>
  </div>
</template>

<script lang="ts" setup>
withDefaults(
  defineProps<{
    image: Image
    imageRatio?: string
  }>(),
  {
    imageRatio: 'aspect-[3/4]'
  }
)

const $img = useImage()

// cover image loading state
const loaded = ref(false)
const cover = ref()
</script>
